import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

interface DetailsProp extends HTMLAttributes<HTMLParagraphElement> {
  className?: string;
  description?: string;
}

export const Details: FC<PropsWithChildren<DetailsProp>> = ({ children, className }, props) => {
  return (
    <p
      className={`font-rubik font-light text-black phone:text-sm tablet:text-base laptop:text-lg 
      desktop:text-lg mb-5 ${className}`}
      {...props}
    >
      {children}
    </p>
  );
};

// Text Component 
// Used to handle "\n"
export const Details2: FC<PropsWithChildren<DetailsProp>> = ({ children, className, description }, props) => {
  const tempDescription = description?.split('\n');
  return (
    <p
      className={`font-rubik font-light text-black phone:text-sm tablet:text-base laptop:text-lg 
      desktop:text-lg mb-5 ${className}`}
      {...props}
    >
      {
        tempDescription?.map((textPart: string, index: number) => {
          return (
            <>
            {
              index > 0
              ? <br/>
              : <></>
            }
            <span>{textPart}</span>
            </>
          )
        })
      }
      &nbsp;{children}
    </p>
  );
};
