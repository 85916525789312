// import React, { useEffect, useState } from 'react';
import React from 'react';
// import HomepageBanner from "@assets/videos/GPA_Launch_HomeBanner.mp4";
import { Images, Icons, BDAImages } from '@core';
// import { getFileData } from '@services';
// import { FILEDATA } from '@types';
// import { API } from '@src/config';
import { HomeLearnMoreItem } from '@src/components';

export const Home = () => {
  // const [file, setFile] = useState<FILEDATA[]>([]);

  // const getData = async () => {
  //   const fileDatas = await getFileData();
  //   setFile(fileDatas.data);
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  return (
    <>
      {/* <div
        className="items-center pt-4 mt-20 phone:justify-center phone:flex-col phone:px-8 tablet:px-0 tablet:block related desktop:mt-28"
        style={{
          minHeight: "500px",
          backgroundImage: `url(${Images.GPABeattheBeakBannerv2})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div
          className="flex flex-col items-center pt-12"
          style={{
            maxWidth: "750px",
          }}
        >
          <img
            alt="logo"
            className="right-12 h-32 w-42"
            src={Images.BeatThePeakLogov2}
          />
          <h1
            className="text-center text-white font-newmake phone:text-3xl phone:mb-6 tablet:mt-6 tablet:text-6xl"
            style={{
              maxWidth: "750px",
            }}
          >
            YOU HAVE THE POWER TO STOP{" "}
            <span className="with-outline">OUTAGES</span>
          </h1>
          <a href="/beatthepeak" target="_blank">
            <button
              className="block py-2 px-4 text-2xl font-bold text-white rounded-full phone:text-lg bg-green phone:w-full phoneWide:w-max phone:mx-auto"
              style={{
                maxWidth: "300px",
                backgroundColor: "#DC3545",
              }}
              type="button"
            >
              BEAT THE PEAK
            </button>
          </a>
        </div>
      </div> */}
      {/* <div className="relative mt-20 h-full tablet:max-h-400px desktop:mt-28">
        <div className="absolute h-full bg-black/50" />
        <img
          alt="Mawar BG"
          className="object-cover absolute w-full h-full opacity-40 z-1 tablet:opacity-100"
          src={Images.HomePageAugust}
        />
        <div className="absolute inset-0 z-10 h-full bg-black bg-opacity-10" />
        <section className="flex absolute z-20 items-center w-full h-full tablet:w-auto laptop:px-40 tablet:px-36 phone:px-10">
          <div className="flex flex-col py-20 w-full">
            <div className="flex relative flex-col justify-center items-center ml-0 tablet:-ml-20">
              <img
                alt="GPA55thlogo2"
                className="mr-6 mb-4 w-16 h-16"
                src={Images.GPA55thlogo2}
              />
              <h2 className="mb-5 text-6xl font-bold text-center font-jubilat text-blue tablet:text-4xl tablet:text-left">
                Bringing <br />
                Energy <br />
                Solutions <br />
                to You
              </h2>
            </div>
          </div>
        </section>
      </div> */}
      <div className="relative mt-20 desktop:mt-28" style={{ overflow: 'hidden' }}>
        {/* Mobile image */}
        <div className="relative w-full phone:block phoneMd:block phoneWide:block phoneLg:block tablet:hidden tabletWide:hidden laptopSm:hidden laptop:hidden desktop:hidden desktopWide:hidden">
          <img
            alt="Energy"
            className="w-full h-auto"
            src={Images.customerServiceMobile} // Mobile image
          />
        </div>
        {/* Desktop image */}
        <div className="hidden relative w-full phone:hidden phoneMd:hidden phoneWide:hidden phoneLg:hidden tablet:block tabletWide:block laptopSm:block laptop:block desktop:block desktopWide:block">
          <img
            alt="Energy"
            className="w-full h-auto"
            src={Images.customerServiceWeb} // Desktop image
          />
        </div>
        <div className='absolute top-0 w-full flex flex-row justify-center items-center'>
          <div className='mt-48 pt-8 phoneLg:mt-96 phoneLg:pt-32 tablet:w-1/2 tabletWide:mt-48 tabletWide:pt-0 tabletWide:px-14 laptopSm:mt-72 laptopSm:px-20 flex flex-row justify-center tabletWide:justify-start items-center'>
            <a
              className="flex flex-row justify-center items-center w-40 h-10 border border-darkblue bg-white rounded-full font-rubik font-medium text-base desktop:text-xl text-darkblue"
              href="https://docs.google.com/forms/d/e/1FAIpQLScqNc2SSGRwVIWuk4EUc6ivT7XubkLANlfjR3alxWAecz93Bw/viewform"
              rel="noreferrer"
              target="_blank"
            >
              Take Survey
            </a>
          </div>
        </div>
        <div className='absolute bottom-8 w-full flex flex-row justify-center items-center'>
          <div className='tablet:w-1/2 flex flex-row justify-center tabletWide:justify-end items-center'>
            <a
              className="text-sm tabletWide:text-white"
              href="/assets/CustomerSurveyRules2.pdf"
              rel="noreferrer"
              target="_blank"
            >
              Click here for rules and regulations
            </a>
          </div>
        </div>
      </div>

      <section className="pb-20 bg-lightgray laptop:px-28 tablet:px-20 phone:px-10">
        <section className="grid grid-cols-1 gap-6 py-20 w-full tablet:grid-cols-2 tabletWide:grid-cols-3 tablet:grid-rows-3 tabletWide:grid-rows-2 tabletWide:gap-8">
          <HomeLearnMoreItem
            icon={Icons.OnlinePayment}
            link="https://paygpa.com/"
            title="Make an online payment"
          />
          <HomeLearnMoreItem icon={Icons.WattsNew} link="/news-and-media" title="See Watts New" />
          <HomeLearnMoreItem
            icon={Icons.EnergyTips}
            link="https://energysense.guampowerauthority.com/savings-tips"
            title="Ways to Save Energy"
          />
          <HomeLearnMoreItem
            icon={Icons.TransferService}
            link="/how-do-i/start-stop-move-services"
            title="Start, Stop, & Move Service"
          />
          <HomeLearnMoreItem
            icon={Icons.MyEnergyGuam}
            link="https://www.myenergyguam.com/HomeConnect/login.html"
            title="View My Energy Usage"
          />
          <HomeLearnMoreItem
            icon={Icons.ViewOuttages}
            link="https://web.guamalerts.com/notification/gpa"
            title="View Outages"
          />
        </section>

        <div
          className="relative w-full carousel slide carousel-fade carousel-dark carousel__height"
          data-bs-ride="carousel"
          id="carouselDarkVariant"
        >
          <div className="flex absolute right-0 bottom-0 left-0 justify-center p-0 mb-2 carousel-indicators">
            <button
              aria-current="true"
              aria-label="Slide 1"
              className="carousel-buttons active"
              data-bs-slide-to="0"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="1"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="2"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="3"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="4"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="5"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="6"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="7"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            {/* <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="8"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="9"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="10"
              data-bs-target="#carouselDarkVariant"
              type="button"
            /> */}
          </div>

          <div className="overflow-hidden relative w-full h-full carousel-inner">
            {/* <div className="relative w-full h-full carousel-item active">
              <div className="absolute top-0 left-0 z-10 w-full laptop:p-14 phone:p-8">
                <p className="mt-1 text-5xl font-normal text-white font-jubilat laptop:tracking-tight laptop:text-custom tablet:text-4xl phone:text-2xl">
                  Additional $100 power credit for You!
                </p>
                <p className="mt-2 text-4xl font-normal text-white font-rubik laptopSm:mt-5 tablet:text-3xl phone:text-base phoneMd:text-xl">
                  <i>Prugråman Ayuda Para I Taotao-ta Energy Credit</i>
                </p>
                <p className="mt-2 text-3xl font-normal text-white font-rubik laptopSm:mt-5 tablet:text-2xl phone:text-base">
                  Public Law 37-104 extended additional credits of $100 per month for April -
                  September 2024 bringing financial relief to residential, master-meter residential,
                  and commercial customers.
                </p>
                <p className="mt-2 text-3xl font-normal text-white font-rubik laptopSm:mt-5 tablet:text-2xl phone:text-base">
                  Credits will be applied monthly upon receipt of funds from DOA.
                </p>
                <div className="mt-2 phoneMd:mt-5">
                  <a href="/assets/GPA_PowerCredit_FAQs061724.pdf" rel="noreferrer" target="_blank">
                    <button
                      className="py-3 mt-3 text-white rounded-full bg-orange text-bold laptop:px-8 phone:px-6 phone:text-sm"
                      type="button"
                    >
                      More Info
                    </button>
                  </a>
                </div>
              </div>
              <img
                alt="PowerButton"
                className="object-center absolute top-0 left-0 z-0 w-full h-full object-fit"
                src={Images.GPAMawarBG}
              />
            </div> */}

            {/* <div
              className="relative w-full h-full carousel-item"
              style={{
                minHeight: '350px',
                backgroundImage: `url(${Images.GPAPotentialOutageBanner})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <div className="relative z-20 items-center phone:flex-col tabletWide:flex-row">
                <img
                  alt="Alert"
                  className="object-cover mx-auto w-24 h-24 phone:mt-16 tabletWide:mt-18 tabletWide:ml-20"
                  src={Images.Alert}
                />
                <div className="flex flex-col tabletWide:items-start phone:items-center phoneLg:ml-0 phone:px-10">
                  <h1 className="mb-1 font-normal font-bold text-white font-rubik phone:text-lg tablet:text-6xl laptop:text-6xl desktop:text-6xl phone:mt-0 tablet:mt-5 phone:text-center tabletWide:text-left">
                    Aug/Sep 2024
                  </h1>
                  <h1 className="mb-5 font-light text-white font-rubik phone:text-sm tablet:text-base laptop:text-4xl desktop:text-4xl phone:text-center tabletWide:text-left phone:text-xs">
                    POTENTIAL ROTATING OUTAGE SCHEDULE
                  </h1>
                  <a href="/potential-rotating-outage-schedule" rel="noreferrer" target="_blank">
                    <button
                      className="py-3 mt-3 text-white rounded-full bg-orange text-bold laptop:px-8 phone:px-6 phone:text-sm"
                      type="button"
                    >
                      More Info Here
                    </button>
                  </a>
                </div>
              </div>
            </div> */}

            {/* <div className="relative w-full h-full carousel-item">
              <div className="absolute top-0 left-0 z-30 w-full h-full">
                <a
                  className="block w-full h-2/5"
                  href="https://energy.guam.gov/liheap/?fbclid=IwZXh0bgNhZW0CMTAAAR13s9g0YsalXIwJ6ZJMFCwau7UpV9-rDG9gq1zqdjL9GmGmj4boSNWFyyk_aem_AdHU8tMjb2iOGLykN-unmVA4p2ttdAdF-P2WdMWZNMMYv7O8J20E3Lbg2K-WlGrKnwjQTOWebDRQfeCsM4oAcuhl"
                  rel="noreferrer"
                  target="_blank"
                >
                  {' '}
                </a>
                <div className="w-full h-2/5 bg-transparent laptop:px-14 phone:px-8">
                  <div className="flex flex-col justify-around items-start w-full h-full bg-white tabletWide:w-7/10 laptopSm:w-3/4">
                    <p className="text-3xl font-normal font-GothamMedium text-darkblue laptopSm:text-2xl tablet:text-xl phone:text-xs phoneMd:text-sm">
                      provides a one-time benefit payment of up to $1,000 to qualifying households
                      facing an energy crisis.
                    </p>
                    <div className="tablet:flex tablet:flex-row tablet:justify-between tablet:items-center">
                      <p className="mb-4 text-3xl font-normal tablet:mb-0 tablet:w-3/4 font-GothamMedium text-darkblue laptopSm:text-2xl tablet:text-xl phoneMd:text-sm phone:text-xs">
                        Interested GPA customers can visit the Guam Energy Office to see if they
                        qualify.
                      </p>
                      <a
                        className="tablet:w-1/4"
                        href="https://energy.guam.gov/liheap/?fbclid=IwZXh0bgNhZW0CMTAAAR13s9g0YsalXIwJ6ZJMFCwau7UpV9-rDG9gq1zqdjL9GmGmj4boSNWFyyk_aem_AdHU8tMjb2iOGLykN-unmVA4p2ttdAdF-P2WdMWZNMMYv7O8J20E3Lbg2K-WlGrKnwjQTOWebDRQfeCsM4oAcuhl"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <button
                          className="flex flex-row justify-center items-center py-3 text-2xl font-bold tracking-tighter rounded-full bg-dandelion font-GothamMedium text-darkblue laptop:px-8 phone:px-6 laptopSm:text-xl tablet:text-lg phoneMd:text-base phone:text-sm"
                          type="button"
                        >
                          CLICK HERE
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-start h-1/5 laptop:px-14 phone:px-8">
                  <p className="text-2xl font-normal text-transparent font-GothamMediumItalic tablet:text-xl phoneMd:text-base phone:text-sm">
                    Cycle 3 Application Period ends September 13, 2024.
                  </p>
                  <a
                    className="text-2xl font-normal font-GothamBoldItalic text-darkblue tablet:text-xl phoneMd:text-base phone:text-sm"
                    href="https://energy.guam.gov/liheap/?fbclid=IwZXh0bgNhZW0CMTAAAR13s9g0YsalXIwJ6ZJMFCwau7UpV9-rDG9gq1zqdjL9GmGmj4boSNWFyyk_aem_AdHU8tMjb2iOGLykN-unmVA4p2ttdAdF-P2WdMWZNMMYv7O8J20E3Lbg2K-WlGrKnwjQTOWebDRQfeCsM4oAcuhl"
                    rel="noreferrer"
                    target="_blank"
                  >
                    www.energy.guam.gov
                  </a>
                </div>
              </div>
              <div className="absolute top-0 left-0 z-10 w-full h-full">
                <div className="flex flex-col justify-center items-start w-full h-2/5 tabletWide:w-7/10 laptopSm:w-3/4 laptop:px-14 phone:px-8">
                  <p className="mt-1 text-white font-GothamBlack laptop:tracking-tight laptopSm:text-4xl tablet:text-3xl phoneMd:text-2xl phone:text-xl">
                    Guam Energy Office Low Income Home Energy Assistance Program (LIHEAP)
                  </p>
                </div>
                <div className="w-full h-2/5 bg-white laptop:px-14 phone:px-8" />
                <div className="h-1/5" />
              </div>
              <div className="flex absolute top-0 left-0 z-20 flex-row items-center w-full h-full">
                <img
                  alt="PowerButton"
                  className="hidden object-contain object-right w-full tablet:block laptopSm:h-300px"
                  src={Images.BGLightBulb}
                />
              </div>
              <img
                alt="PowerButton"
                className="hidden object-contain object-right absolute top-0 left-0 z-10 w-full h-full"
                src={Images.BGLightBulb}
              />
              <img
                alt="PowerButton"
                className="object-center absolute top-0 left-0 z-0 w-full h-full object-fit"
                src={Images.YellowGradient}
              />
            </div> */}

            <div
              className="float-left relative w-full h-full carousel-item active"
              style={{
                minHeight: '350px',
                backgroundImage: `url(${BDAImages.BDABanner})`,
                backgroundPosition: 'left',
                backgroundSize: 'cover',
              }}
            >
              <div className="flex flex-col items-center w-full h-full tabletWide:justify-start phone:justify-center">
                <div className="flex flex-col justify-center items-center mr-auto w-full tabletWide:w-3/5 tabletWide:items-start laptop:p-14 phone:p-8">
                  <h1 className="mb-1 font-bold text-white font-jubilat phone:text-2xl tablet:text-5xl laptop:text-6xl desktop:text-6xl phone:mt-0 tablet:mt-5 phone:text-center tabletWide:text-left">
                    Addressing Capacity
                  </h1>
                  <h1 className="mb-1 font-bold text-white font-jubilat phone:text-2xl tablet:text-5xl laptop:text-6xl desktop:text-6xl phone:mt-0 tablet:mt-5 phone:text-center tabletWide:text-left">
                    Shortfall
                  </h1>
                  <div className="mt-5">
                    <a href="/brighterdaysahead">
                      <button
                        className="py-3 text-white rounded-full bg-orange text-bold laptop:px-8 phone:px-6 phone:text-sm"
                        type="button"
                      >
                        Learn More
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative w-full h-full carousel-item">
              <div className="flex absolute top-0 left-0 z-10 flex-col justify-center items-start w-full h-full laptop:p-8 phone:p-8">
                <div className="flex flex-col tabletWide:flex-row">
                  <div
                    className="flex justify-between items-center pt-12"
                    style={{
                      maxWidth: '750px',
                    }}
                  >
                    <div className="phoneWide:ml-0 phoneLg:ml-8 tabletWide:ml-0">
                      <h1
                        className="text-center text-white font-newmake phone:text-3xl tablet:text-6xl tabletWide:mr-24"
                        style={{
                          maxWidth: '750px',
                        }}
                      >
                        YOU HAVE THE POWER TO STOP <span className="with-outline">OUTAGES</span>
                      </h1>
                      <a href="/beatthepeak" target="_blank">
                        <button
                          className="block py-2 px-4 mb-12 text-2xl font-bold text-white rounded-full phone:text-lg bg-green phone:w-full phoneWide:w-max phone:mx-auto"
                          style={{
                            maxWidth: '300px',
                            backgroundColor: '#DC3545',
                          }}
                          type="button"
                        >
                          BEAT THE PEAK
                        </button>
                      </a>
                    </div>
                  </div>
                  <img
                    alt="logo"
                    className="ml-48 h-24 w-42 tabletWide:mt-16 laptopSm:mt-0 laptopSm:mb-16 phone:hidden tabletWide:block"
                    src={Images.BeatThePeakLogov2}
                  />
                </div>
              </div>
              <img
                alt="PowerButton"
                className="object-center absolute top-0 left-0 z-0 w-full h-full object-fit phone:hidden tabletWide:block"
                src={Images.BeatThePeakCarousel}
              />
              <img
                alt="PowerButton"
                className="object-center absolute top-0 left-0 z-0 w-full h-full object-fit phone:block tabletWide:hidden"
                src={Images.BeatThePeakRed}
              />
            </div>
            {/*
            <div className="float-left relative w-full h-full carousel-item">
              <div className="flex w-full h-full tabletWide:flex-row phone:flex-col">
                <div className="flex absolute top-0 right-0 bottom-0 left-0 flex-col justify-center items-center w-auto w-full anniv-carousel tabletWide:w-3/5 tabletWide:bottom-14 tabletWide:left-96 tablet:items-start laptop:px-14 phone:px-8">
                  <h2 className="mb-5 font-bold text-center text-white font-jubilat tablet:text-5xl phone:text-4xl tablet:text-left">
                    55 Years of Bringing Energy Solutions to You.
                  </h2>

                  <div className="mt-5">
                    <a
                      href="/corporate/about-us#about-us-general-manager"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <button
                        className="py-3 text-white rounded-full bg-orange text-bold laptop:px-8 phone:px-6 phone:text-sm"
                        type="button"
                      >
                        Message from the General Manager
                      </button>
                    </a>
                  </div>
                </div>
                <img
                  alt="PowerButton"
                  className="object-center overflow-hidden top-0 right-0 bottom-0 left-0 h-full absolut object-fit"
                  src={Images.FiveFiveYearsMayActiveCarousel}
                />
              </div>
            </div> */}

            <div className="float-left relative w-full h-full carousel-item">
              <div className="w-full h-8 csd-home__linear-gradient" />
              <div className="flex w-full h-full tabletWide:flex-row phone:flex-col bg-lightGreen">
                <div className="flex flex-col justify-center mr-auto w-auto tabletWide:w-3/5 laptop:p-14 phone:p-8">
                  <h2 className="font-jubilat text-blue laptop:text-5xl phone:text-2xl">
                    Customer Service Directory
                  </h2>
                  <p className="mt-2 text-sm font-normal text-white font-rubik laptopSm:mt-5 laptop:text-xl phone:text-lg">
                    As our valued customer, we offer tips to save money on your power bill, tools to
                    manage your account and resource information for all things power! Get the power
                    to save now!
                  </p>
                  <div className="mt-5">
                    <a
                      href="https://admin.guampowerauthority.com/uploads/GPA_Customer_Directory_Guam_Webz_FEB_2023_966494ae6a.pdf?updated_at=2023-02-08T06:16:58.309Z"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <button
                        className="py-3 text-white rounded-full bg-orange text-bold laptop:px-8 phone:px-6 phone:text-sm"
                        type="button"
                      >
                        Click Here
                      </button>
                    </a>
                  </div>
                </div>
                <img
                  alt="PowerButton"
                  className="object-contain object-bottom overflow-hidden w-full tabletWide:w-2/5 laptop:w-auto"
                  src={Images.ExpertElectrician}
                />
              </div>
            </div>

            <div className="relative w-full h-full carousel-item">
              <div className="absolute top-0 left-0 z-10 w-full laptop:p-14 phone:p-8">
                <p className="mt-12 w-1/2 text-6xl font-bold tracking-wide text-white font-jubilat laptop:text-6xl tabletWide:text-5xl tablet:text-left phone:text-left tablet:text-5xl phone:text-2xl tabletWide:text-center laptop:text-center phone:text-center phone:w-full tabletWide:w-1/2 laptop:w-1/2 phone:mt-40 tabletWide:mt-16 laptop:mt-12">
                  We are
                  <br />
                  #CommunityPowered
                </p>
                <div className="mt-5 w-1/2 text-center tablet:text-left phone:text-left tabletWide:text-center laptop:text-center phone:text-center phone:w-full tabletWide:w-1/2 laptop:w-1/2">
                  <a
                    href="https://gpa.guamunity.com/community-powered"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <button
                      className="py-3 mt-3 text-white rounded-full bg-orange text-bold laptop:px-8 phone:px-6 phone:text-sm"
                      type="button"
                    >
                      Click Here
                    </button>
                  </a>
                </div>
              </div>
              <img
                alt="PowerButton"
                className="object-cover object-top absolute top-0 left-0 z-0 w-full h-full phone:object-left"
                src={Images.GpaCommunityPowered}
              />
            </div>

            <div className="float-left relative w-full h-full bg-white carousel-item">
              <div className="flex w-full h-full tabletWide:flex-row phone:flex-col">
                <div className="flex flex-col justify-center mr-auto w-auto tabletWide:w-3/5 laptop:p-14 phone:p-8">
                  <h2 className="font-jubilat text-blue laptop:text-5xl phone:text-2xl">
                    Get outage alerts
                    <br className="hidden laptopSm:block" />
                    on your street
                  </h2>
                  <p className="mt-2 text-sm font-normal text-black font-rubik laptopSm:mt-5 laptop:text-xl phone:text-lg">
                    GPA Power Alerts is a 2-way text messaging service, providing customers with
                    real time information about any scheduled or unscheduled power outage affecting
                    their home and/or business, and estimated time of restoration.
                  </p>
                  <div className="mt-5">
                    <a href="/power-alerts">
                      <button
                        className="py-3 text-white rounded-full bg-orange text-bold laptop:px-8 phone:px-6 phone:text-sm"
                        type="button"
                      >
                        About Alerts
                      </button>
                    </a>
                  </div>
                </div>
                <img
                  alt="PowerButton"
                  className="object-cover overflow-hidden w-full tabletWide:w-2/5 laptop:w-auto"
                  src={Images.PowerAlerts}
                />
              </div>
            </div>

            <div className="float-left relative w-full h-full carousel-item bg-blue">
              <div className="flex px-8 w-full h-full tabletWide:flex-row phone:flex-col">
                <div className="flex flex-col justify-center mr-auto w-auto tabletWide:w-1/2 laptop:pl-6 laptop:pt-14 laptop:pr-14 laptop:pb-14 phone:px-0 phone:py-8">
                  <h2 className="text-white font-rubik laptop:text-4xl phone:text-2xl">
                    DOWNLOAD THE PAYGPWA MOBILE APP
                  </h2>
                  <p className="mt-5 font-bold text-white font-rubik laptop:text-4xl phone:text-lg">
                    Now with more features to easily pay, view and manage your accounts
                  </p>
                  <div className="flex flex-row gap-2 mt-5 w-auto phoneWide:w-3/5">
                    <a href="https://apps.apple.com/us/app/pay-gpwa/id1593835535">
                      <img alt="Download through Apple App Store" src={Images.AppStore} />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.xtendly.paygpwa">
                      <img alt="Download through Google Play Store" src={Images.PlayStore} />
                    </a>
                  </div>
                </div>
                <div className="object-cover overflow-auto pt-0 w-full tabletWide:pt-10 tabletWide:w-1/2 tabletWide:flex tabletWide:items-end laptopSm:w-2/5 laptop:w-2/5 desktopWide:w-2/6">
                  <img alt="PayGPWA App" className="block" src={Images.PayGWA} />
                </div>
                {/* <img alt="PayGPWA App" className="object-cover overflow-auto pt-0 w-full tabletWide:w-1/2 laptop:w-auto tabletWide:pt-10" src={Images.PayGWA} /> */}
              </div>
            </div>

            <div className="float-left relative w-full h-full bg-white carousel-item">
              <div className="flex w-full h-full tabletWide:flex-row phone:flex-col">
                <div className="flex flex-col justify-center mr-auto w-auto laptopSm:w-3/5 tabletWide:w-3/6 laptop:p-14 phone:p-8">
                  <h2 className="font-jubilat text-blue laptop:text-5xl phone:text-2xl">
                    Learn how GPA's Clean Energy Master Plan will bring Guam to 50% renewable energy
                    by 2030 and 100% by 2045.
                  </h2>
                  {/* <p className="mt-2 font-normal text-black font-rubik laptop:text-xl phone:text-lg">
                      The Clean Energy Master Plan
                    </p> */}
                  <a className="mt-5" href="/gpa-initiatives/clean-energy-master-plan">
                    <button
                      className="py-3 font-normal text-white rounded-full font-rubik bg-orange text-bold laptop:px-8 phone:px-6 phone:text-sm"
                      type="button"
                    >
                      Clean Energy Master Plan
                    </button>
                  </a>
                </div>
                <div className="object-cover object-center overflow-hidden flex-1 w-full tabletWide:w-3/6 tabletWide:flex tabletWide:items-center laptopSm:w-2/5">
                  <div className="youtube-video__container">
                    <iframe
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      className="youtube-video__iframe"
                      frameBorder="0"
                      height="720"
                      src="https://www.youtube.com/embed/TwlmVXmSk34"
                      title="GPA's Clean Energy Master Plan"
                      width="1280"
                    />
                  </div>
                </div>
                {/* <img
                    alt="GPA YouTube Channel"
                    className="object-cover object-center overflow-hidden w-full tabletWide:w-2/5 laptop:w-auto"
                    src={Images.WatchGPA}
                  /> */}
              </div>
            </div>

            <div className="float-left relative w-full h-full bg-white carousel-item">
              <div className="flex w-full h-full tabletWide:flex-row phone:flex-col">
                <div className="w-full h-full">
                  <a
                    href="https://guampowerauthority.myenergyxpert.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="relative w-full h-full">
                      {/* Mobile image */}
                      <img
                        alt="Energy"
                        className="block w-full h-full phone:block phoneMd:block phoneWide:block phoneLg:block tablet:hidden tabletWide:hidden laptopSm:hidden laptop:hidden desktop:hidden desktopWide:hidden"
                        src={Images.EnergyMobile} // Mobile image
                      />
                      {/* Desktop image */}
                      <img
                        alt="Energy"
                        className="hidden w-full h-full phone:hidden phoneMd:hidden phoneWide:hidden phoneLg:hidden tablet:block tabletWide:block laptopSm:block laptop:block desktop:block desktopWide:block"
                        src={Images.Energy} // Desktop image
                      />
                      s
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center phone:pt-14 tabletWide:px-64 tablet:px-20">
          <h1 className="text-center font-jubilat text-blue laptop:mb-14 phone:mb-10 laptop:text-5xl tablet:text-5xl phone:text-4xl">
            GPA will achieve a 25% renewable energy portfolio mix by 2024
          </h1>
          <p className="mb-5 font-normal text-center text-black font-rubik laptop:text-2xl tablet:text-2xl phone:text-lg">
            Learn about our <b>Clean Energy Master Plan</b> and how it benefits you and our island.
          </p>
          <a href="/gpa-initiatives">
            <button
              className="text-white bg-gradient-to-r rounded-full from-clean-green to-clean-lightyellow text-bold laptop:px-14 laptop:py-4 phone:px-10 phone:py-3"
              type="button"
            >
              Learn about our vision
            </button>
          </a>
        </div>
      </section>
      <section className="flex justify-center items-center py-10 bg-white tablet:px-20 phone:px-10">
        <div className="flex flex-col justify-center items-center w-full max-w-5xl">
          <div className="flex items-center p-8 w-full tablet:gap-10 tablet:flex-row phone:flex-col laptop:h-44 phone:h-auto">
            <img
              alt="PowerButton"
              className="w-full rounded-full border-2 border-black max-w-150px"
              src={Images.Doa}
            />
            <p className="font-normal text-justify text-black font-rubik laptop:text-xl phone:lg tabletWide:mt-0 phone:mt-10">
              Available now. Funding is available through the Department of Administration with the
              Emergency Rental Assistance (ERA) program, to assist households that are unable to pay
              rent and utility expense dating back to March 13, 2020. Eligible households may
              receive up to 15 months of assistance, to include current rent and utility payments,
              and arrears. Ratepayers are encouraged to visit www.doa.guam.gov for more information.
            </p>
          </div>
          <a className="mt-5" href="/how-do-i/get-financial-assistance">
            <button
              className="py-3 font-normal text-white rounded-full font-rubik bg-orange text-bold laptop:px-8 phone:px-6 phone:text-sm"
              type="button"
            >
              Read More
            </button>
          </a>
        </div>
      </section>
    </>
  );
};
